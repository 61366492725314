<template>
    <div class="credit-payment">
        <credit-saved-card v-if="showSavedCard"  />
        <credit-card-form v-else />

        <Modal
            modalId="errorPayment"
            ref="errorPayment"
        >
            <template v-slot:title>Problema com Cartão Salvo</template>
            <template v-slot:body>
                Identificamos que o cartão salvo está expirado.
                Tente atualizar os dados do cartão ou selecionar outro método de pagamento..
            </template>
            <template v-slot:footer>
                <ButtonVue
                data-dismiss="modal"
                @click="handleCardForm"
                    label="Entendi"
                    class="sm"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import CreditCardForm from './CreditCardForm.vue'
import PersonalDataForm from '../../PersonalData/PersonalDataForm.vue'
import SubmitTerms from '../SubmitTerms.vue'
import CheckboxVue from '../../../ui/CheckboxVue.vue'
import CreditSavedCard from './CreditSavedCard.vue'
import Modal from '../../../ui/Modal.vue'
import ButtonVue from '../../../ui/ButtonVue.vue'
import IncompleteRegistration from '../../PersonalData/IncompleteRegistration.vue';
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters('account', {
            userTypeRegister: 'userTypeRegister',
            isLogged: 'isLogged',
            customer: 'customer',
            savedCards: 'savedCards',
            customerNumber: 'customerNumber',
        }),
        ...mapGetters('checkoutOnePage', {
            totals: 'totals',
            forms: "forms",
            resources: 'resources',
            tripsPassengers: 'tripsPassengers',
            reservamosData: 'reservamosData',
            activeInsurance: 'activeInsurance',
            device: 'device',
        }),
    },
    components: {
        CreditCardForm,
        SubmitTerms,
        PersonalDataForm,
        CheckboxVue,
        CreditSavedCard,
        Modal,
        ButtonVue,
        IncompleteRegistration,
    },
    props: {},
    created() {
        this.$root.$refs.CreditPayment = this;
    },
    mounted() {
        this.canBeShowSavedCard()
    },
    data() {
        return {
            showSavedCard: true,
            form: {
                creditCard: {
                    paymentMethod: 'CIELO_CREDIT_CARD',
                    cardNumber: '',
                    cardName: '',
                    cardYear: '',
                    cardMonth: '',
                    cardCvv: '',
                    installments: '',
                    newsletter: false,
                    saveCard: false,
                    cardBrand: '',
                    selectedCardUUID: null
                },
            },
            errors: {
                cardNumber: '',
                cardName: '',
                cardYear: '',
                cardMonth: '',
                cardCvv: '',
                installments: '',
            },
            canBeSubmitted: false,
        }
    },
    methods: {
        canBeShowSavedCard(){
            const hasSavedCards = this.savedCards !== null
            this.showSavedCard = this.isLogged && hasSavedCards
        },
        detectCardBrand(cardNumber) {
            cardNumber = cardNumber.replace(/\s+/g, '');
            const cardPatterns = {
                Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
                "Master Card": /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
                Amex: /^3[47][0-9]{13}$/,
                Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
                Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
                Jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
                Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297)|(5067)|(4576)|(4011)|(506699)|(5067)|(5066)|(5090)|(5098)|(5099)|(6505)|(6504)|(6503)|(6502)|(5064)|(5078)|(5096)|(6062)|(6516)|(6360)|(5041)|(6054)|(5065)|(5062)|(6064)|(6375)|(6516)|(6373)|(6500)|(6516)|(6514)|(6504)|(6503)|(6501))[0-9]{10,12})|((506699)|(5067))[0-9]{9,11})$/,
            };

            for (const brand in cardPatterns) {
                if (cardPatterns[brand].test(cardNumber)) {
                    this.form.creditCard.cardBrand = brand;
                    return;
                }
            }
            this.form.creditCard.cardBrand = 'unknown';
        },
        validateCreditCardForm() {
            this.errors = {
                cardNumber: '',
                cardName: '',
                cardYear: '',
                cardMonth: '',
                cardCvv: '',
                installments: '',
            };

            this.validateCardNumber();
            this.validateCardName();
            this.validateCardYear();
            this.validateCardMonth();
            this.validateCardCvv();
            this.validateInstallments();

            this.canBeSubmitted = !Object.values(this.errors).some(error => error);
        },
        validateCardNumber() {
            if (!this.form.creditCard.cardNumber) {
                this.errors.cardNumber = this.resources.errors.cardNumber;
            }
        },
        validateCardName() {
            if (!this.form.creditCard.cardName) {
                this.errors.cardName = this.resources.errors.cardName1;
            } else if (!/^[a-zA-Z\s]+$/.test(this.form.creditCard.cardName)) {
                this.errors.cardName = this.resources.errors.cardName2;
            }
        },
        validateCardYear() {
            const currentYear = new Date().getFullYear();

            if (!this.form.creditCard.cardYear) {
                this.errors.cardYear = this.resources.errors.cardYear1;
            } else if (isNaN(this.form.creditCard.cardYear) || this.form.creditCard.cardYear < currentYear) {
                this.errors.cardYear = this.resources.errors.cardYear2;
            }
        },
        validateCardMonth() {
            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth() + 1;

            if (!this.form.creditCard.cardMonth) {
                this.errors.cardMonth = this.resources.errors.cardMonth1;
            } else if (isNaN(this.form.creditCard.cardMonth) || this.form.creditCard.cardMonth < 1 || this.form.creditCard.cardMonth > 12) {
                this.errors.cardMonth = this.resources.errors.cardMonth2;
            } else if (this.form.creditCard.cardYear == currentYear && this.form.creditCard.cardMonth < currentMonth) {
                this.errors.cardMonth = this.resources.errors.cardMonth3;
            }
        },
        validateCardCvv() {
            if (!this.form.creditCard.cardCvv) {
                this.errors.cardCvv = this.resources.errors.cardCvv1;
            } else if (!/^\d{3,4}$/.test(this.form.creditCard.cardCvv)) {
                this.errors.cardCvv = this.resources.errors.cardCvv2;
            }
        },
        validateInstallments() {
            if (!this.form.creditCard.installments) {
                this.errors.installments = this.resources.errors.installments1;
            } else if (isNaN(this.form.creditCard.installments) || this.form.creditCard.installments <= 0) {
                this.errors.installments = this.resources.errors.installments2;
            }
        },
        updateBillingForm() {
            var billingForm = this.forms.billingForm;

            billingForm.paymentMethod.htmlValue = this.form.creditCard.paymentMethod;
            billingForm.paymentMethod.value = this.form.creditCard.paymentMethod;
            billingForm.creditCardFields.cardNumber.htmlName= 'cardNumber';
            billingForm.creditCardFields.cardNumber.htmlValue = this.form.creditCard.cardNumber;
            billingForm.creditCardFields.cardNumber.value = this.form.creditCard.cardNumber;
            billingForm.creditCardFields.cardType.htmlName = 'cardBrand';
            billingForm.creditCardFields.cardType.htmlValue = this.form.creditCard.cardBrand;
            billingForm.creditCardFields.cardType.value = this.form.creditCard.cardBrand;
            billingForm.creditCardFields.cardOwner.htmlName = 'cardName';
            billingForm.creditCardFields.cardOwner.htmlValue = this.form.creditCard.cardName;
            billingForm.creditCardFields.cardOwner.value = this.form.creditCard.cardName;
            billingForm.creditCardFields.expirationYear.htmlName = 'cardYear';
            billingForm.creditCardFields.expirationYear.htmlValue = this.form.creditCard.cardYear;
            billingForm.creditCardFields.expirationYear.value = this.form.creditCard.cardYear;
            billingForm.creditCardFields.expirationMonth.htmlName = 'cardMonth';
            billingForm.creditCardFields.expirationMonth.htmlValue = this.form.creditCard.cardMonth;
            billingForm.creditCardFields.expirationMonth.value = this.form.creditCard.cardMonth;
            billingForm.creditCardFields.securityCode.htmlName = 'cardCvv';
            billingForm.creditCardFields.securityCode.htmlValue = this.form.creditCard.cardCvv;
            billingForm.creditCardFields.securityCode.value = this.form.creditCard.cardCvv;
            billingForm.creditCardFields.installment.htmlName = 'installments';
            billingForm.creditCardFields.installment.htmlValue = this.form.creditCard.installments;
            billingForm.creditCardFields.installment.value = this.form.creditCard.installments;
            billingForm.creditCardFields.saveCard.htmlName = 'saveCard';
            billingForm.creditCardFields.saveCard.htmlValue = this.form.creditCard.saveCard === true ? 'true' : 'false';
            billingForm.creditCardFields.saveCard.checked = this.form.creditCard.saveCard;
            billingForm.creditCardFields.saveCard.value = this.form.creditCard.saveCard === true ? 'true' : 'false';
            billingForm.subscribe.htmlName = 'newsletter';
            billingForm.subscribe.htmlValue = this.form.creditCard.newsletter === true ? 'true' : 'false';
            billingForm.subscribe.value = this.form.creditCard.newsletter === true ? 'true' : 'false';

            this.$store.commit('checkoutOnePage/setBillingForm', billingForm);
        },
        async submitCardForm() {
            console.info('%c   Submit Payment Process started   ', 'background: #55368B; color: #fff');
            this.$root.$refs.Identification.validateCustomerData();

            this.validateCreditCardForm();
            let validatePassengers = await this.$root.$refs.Passengers.validatePassengers()

            if (validatePassengers.error) {
                const firstErrorElement = document.querySelector('.error');
                if (firstErrorElement) {
                    firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    firstErrorElement.focus();
                }
                console.error('%c   Verify Checkout Passengers   ', 'background: #c81515; color: #fff');

                return;
            }
            const personalFormIsVisible = this.$root.$refs.Identification.showPersonalForm();
            const validatePersonalForm = personalFormIsVisible && !this.$root.$refs.Identification.erros.canBeSubmitted

            const incompleteRegistrationIsVisible = this.$root.$refs.Identification.showIncompleteRegistration()
            const validateIncompleteRegistration = incompleteRegistrationIsVisible && !this.$root.$refs.Identification.erros.canBeSubmitted;

            if (!this.canBeSubmitted || validatePersonalForm || validateIncompleteRegistration)  {
                const firstErrorElement = document.querySelector('.error');
                if (firstErrorElement) {
                    firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    firstErrorElement.focus();
                }

                if (this.showSavedCard == true) {
                    console.error('%c   Verify Checkout Forms   ', 'background: #c81515; color: #fff');
                    $('#errorPayment').modal('show');
                }
                return;
            }
            this.updateBillingForm();

            var buyerData = this.$root.$refs.Identification.customerData;

            buyerData.customerNumber = this.customerNumber;
            buyerData.device = this.device;

            const paymentData = {
                billingForm: JSON.stringify(this.forms.billingForm),
                shippingForm: JSON.stringify(this.forms.shippingForm),
                buyerData: JSON.stringify(buyerData),
                storedPaymentUUID: this.showSavedCard ? this.form.creditCard.selectedCardUUID : '',
            };

            try {
                this.reqSubmitCard(paymentData)

            } catch (error) {
                console.error('Error submitting payment request:', error);
            }
        },
        async reqSubmitCard(paymentData) {
            this.$store.commit('checkoutOnePage/setPaymentMethod', 'card')

            const result = await this.$store.dispatch('checkoutOnePage/submitPaymentReq', paymentData);


            if (result.success) {
                if (this.isLogged) {
                    $('body').trigger('clevertapCheckout', ['Cartão de Crédito', this.customer, this.tripsPassengers, this.totals]);
                }

                this.reservamosData['Payment Type'] = 'card';
                this.reservamosData['Insurance'] = this.activeInsurance;
                this.reservamosData['User Status'] = this.customer.userType;

                let reservamosMeta = { };

                if (this.totals.discounts[0]) {
                    this.reservamosData['Coupon'] = this.totals.discounts[0].name;
                    reservamosMeta['Coupon value'] = this.totals.discounts[0].value;
                }

                if (this.activeInsurance) {
                    reservamosMeta['Insurance value'] = this.totals.insurance.value;
                }

                var reservamosDataAndMeta = this.reservamosData
                reservamosDataAndMeta.meta = reservamosMeta
                $(window).trigger('reservamos:paymentAttempt', reservamosDataAndMeta);
            }

            if (!result.success) {
                const fieldErrors = Object.keys(result.fieldErrors);
                fieldErrors.forEach((error) => {
                    this.errors[error] = result.fieldErrors[error];
                });
            }
        },
        handleCardForm() {
            this.form.creditCard = {
                    paymentMethod: 'CIELO_CREDIT_CARD',
                    cardNumber: '',
                    cardName: '',
                    cardYear: '',
                    cardMonth: '',
                    cardCvv: '',
                    installments: '',
                    newsletter: false,
                    saveCard: false,
                    cardBrand: '',
                    selectedCardUUID: null
                }
            this.showSavedCard = !this.showSavedCard

            if (!this.showSavedCard) {
                this.form.creditCard.selectedCardUUID = null
            } else {
                this.$root.$refs.SelectCards.selectFirstCardUUID()
            }
        },
        errorSaveCreditCard() {
            if (this.showSavedCard) {
                this.handleCardForm()
            }
        }
    }
}
</script>
