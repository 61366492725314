<template>
    <div class="pix-payment">
        <h4 class="mob-heading--small">{{ resources.payment.methodPix }}</h4>
        <PixPaymentData
            v-if="!paymentStarted"
            :submitForm="submitForm"
        />
        <PixPaymentSelected
            v-if="paymentStarted"
        />
    </div>
</template>

<script>
import PixPaymentData from '../Pix/PixPaymentData.vue'
import PixPaymentSelected from '../Pix/PixPaymentSelected.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        PixPaymentData,
        PixPaymentSelected
    },
    computed: {
        ...mapGetters('checkoutOnePage', {
            paymentStarted: 'paymentStarted',
            resources: 'resources',
            tripsPassengers: 'tripsPassengers',
            totals: 'totals',
            reservamosData: 'reservamosData',
            activeInsurance: 'activeInsurance',
        }),
        ...mapGetters('account', {
            isLogged: 'isLogged',
            customer: 'customer',
            userTypeRegister: 'userTypeRegister',
        }),
    },
    methods: {
        async submitForm() {
            this.$root.$refs.Payment.validateCustomerData();
            const personalFormIsVisible = this.$root.$refs.Payment.showPersonalForm();
            const incompleteRegistrationIsVisible = this.$root.$refs.Payment.showIncompleteRegistration()

            const validatePersonalForm = personalFormIsVisible && !this.$root.$refs.Payment.erros.canBeSubmitted
            const validateIncompleteRegistration = incompleteRegistrationIsVisible && !this.$root.$refs.Payment.erros.canBeSubmitted;

            if (validatePersonalForm || validateIncompleteRegistration) {
                const firstErrorElement = document.querySelector('.error');
                if (firstErrorElement) {
                    firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    firstErrorElement.focus();
                }

                console.error('%c   Verify Checkout Forms   ', 'background: #c81515; color: #fff');
                return;
            }

            this.$store.commit('checkout/setPaymentStarted', true)

            if (this.isLogged) {
                $('body').trigger('clevertapCheckout', ['PIX', this.customer, this.tripsPassengers, this.totals]);
            }

            this.reservamosData['Payment Type'] = 'pix'

            this.reservamosData['Insurance'] = this.activeInsurance;
            this.reservamosData['User Status'] = this.customer.userType;

            let reservamosMeta = { };

            if (this.totals.discounts[0]) {
                this.reservamosData['Coupon'] = this.totals.discounts[0].name;
                reservamosMeta['Coupon value'] = this.totals.discounts[0].value;
            }

            if (this.activeInsurance) {
                reservamosMeta['Insurance value'] = this.totals.insurance.value;
            }
            var reservamosDataAndMeta = this.reservamosData
            reservamosDataAndMeta.meta = reservamosMeta
            $(window).trigger('reservamos:paymentAttempt', reservamosDataAndMeta);

            await this.$store.dispatch(
                'checkout/pixSubmitPayment',
                { buyerData: JSON.stringify(this.$root.$refs.Payment.customerData) }
            );
        }
    },
}
</script>

<style scoped></style>
