<template>
    <div class="my-5 sharedTicketPage">

        <div class="container login-page page__accounts">
            <div class="row justify-content-center equal-height">
                <div>
                    <div class="card">
                        <div class="card-body">
                            <h2 class="login__title">Download do cartão de embarque</h2>
                            <div class="row justify-content-center">
                                <p>Seu download iniciará em alguns instantes...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- download -->
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="ticket-download"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            :pdf-margin="10"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="lineItem"
        >
            <section slot="pdf-content">
                <div class="container">
                    <div class="card-body">
                        <div class="ticketBody product-summary-block">
                            <ticket-card v-if="lineItem" :section="sectionParsed" :item="lineItem"></ticket-card>
                            <p class="font-italic" style="margin: 50px 0;">Embarque: para esta viagem é necessário chegar com 30min de antecedência.</p>
                        </div>
                    </div>
                </div>
            </section>
        </vue-html2pdf>
        <div class="d-flex justify-content-center sharedTicket">
            <button-vue v-if="lineItem"
                class="downloadTicket link my-5"
                :class="`download-trigger-${lineItem.UUID}`"
                :data-is-comporte-group="lineItem.isComporteGroup"
                :isLoad="loadDownload"
                label="Download"
                @click="generatePDF(lineItem.UUID, lineItem.isComporteGroup, lineItem.bookingId)"
            />
        </div>

    </div>
</template>

<script>
import TicketCard from './TicketCard.vue';
import Slick from 'vue-slick';
import VueHtml2pdf from "vue-html2pdf";
import ButtonVue from '../ui/ButtonVue.vue';


export default {
    props: ['data', 'section'],
    components: {
        TicketCard,
        Slick,
        VueHtml2pdf,
        ButtonVue,
    },
    data() {
        return {
            loadDownload: false,
            lineItem: null,
            sectionParsed: null
        };
    },
    mounted() {
        this.lineItem = JSON.parse(this.data);
        this.sectionParsed = JSON.parse(this.section);
    },
    methods: {
        isMobile() {
            return window.innerWidth <= 600;
        },
        async generatePDF(UUID, isComporte, bookingId) {
            this.loadDownload = true
            if (isComporte) {
                this.$refs.lineItem.generatePdf();
            } else {
                const getLink = await this.$store.dispatch('tickets/downloadDistribusionLink', bookingId)
                window.open(getLink.url, '_blank')
            }
            this.loadDownload = false
        },
        departureSectionTrip() {
            return this.trip.filter(t => t.tripSection === 'IDA');
        },
        returnSectionTrip() {
            return this.trip.filter(t => t.tripSection === 'VOLTA');
        }
    },
};
</script>

<style lang="scss">
    .sharedTicketPage{
        height: 30vh;
    }
</style>