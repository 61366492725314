<template>
    <div class="submit-terms">
        <ButtonVue
            :label="btnText"
            className="solid mt-3"
            type="submit"
            @click="$emit('trigger-submit')"
        />

        <p class="mob-text--smallest">
            {{ resources.payment.submitTerms }}
                <a class="mob-text--smallest checkout-link" :href="termosUso" target="_blank">
                    {{ resources.payment.termsOfUse }}
                </a>
                {{ resources.base.and }}
                <a class="mob-text--smallest checkout-link" :href="politicaPagamento" target="_blank">
                    {{ resources.payment.paymentPolicy }}
                </a>.
        </p>
    </div>
</template>

<script>
import ButtonVue from '../../ui/ButtonVue.vue'
import routes from '../../../api/routes'
import { mapGetters } from 'vuex'

export default {
    components: {
        ButtonVue,
    },
    props: ['method'],
    computed: {
        ...mapGetters('checkoutOnePage', {
            resources: 'resources',
        }),
    },
    mounted() {
        this.btnText = this.method === 'Pix' ?  this.resources.payment.methodPix : this.resources.payment.methodCreditCard
    },
    data() {
        return {
            termosUso: routes.pages.termosUso,
            politicaPagamento: routes.pages.politicaPagamento,
            politicaCookies: routes.pages.politicaCookies,
            btnText: ''
        }
    },
}
</script>

<style scoped lang="scss">
    .submit-terms {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        text-align: center;

        p {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

</style>
