<template>
    <div class="container">
      <div class="d-flex justify-content-center" v-if="!isCancelled">
        <button-vue
          class="ghost cancelOrder greyButton"
          :label="'Cancelar Pedido ' + $root.$refs.TicketConsult.order.orderNumber"
          data-target="#cancelOrderModal"
          @click="showCancelOrderModal"
        />
        <cancel-modal></cancel-modal>
      </div>
  
      <div class="w-100 d-flex justify-content-between justify-content-md-center px-3 mb-5">
        <button-vue
          class="sm ghost purpleButton mb-2 mb-md-0 mr-md-5 justify-content-md-end"
          :label="!fromOrderDetails ? resources.base?.backTicketVerify || 'Voltar' : resources.base?.backToTrips"
          @click="fromOrderDetails ? goHistory() : goConsult()"
        />
        <button-vue
          class="sm ghost purpleButton mb-2 mb-md-0 ml-md-5 justify-content-md-start"
          :label="resources.base?.backHome || 'Voltar para Home'"
          @click="goHome"
        />
      </div>
    </div>
  </template>
  
  <script>
  import ButtonVue from '../ui/ButtonVue.vue';
  import CancelModal from './CancelModal.vue';
  import { mapState } from 'vuex';
  import routes from '../../api/routes';
  
  export default {
    components: {
      'cancel-modal': CancelModal,
      'button-vue': ButtonVue,
    },
    props: {
      fromOrderDetails: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      ...mapState({
        resources: (state) => state.checkout.resources,
      }),
      isCancelled() {
        return this.$root.$refs.TicketConsult.order.isCancelled;
      },
    },
    data() {
        return {
            staticPath: routes.urlStatic,
            fromOrderDetails: false,
        };
    },
    methods: {
      showCancelOrderModal() {
        $('#cancelOrderModal').modal('show');
      },
      goHome() {
        window.location.href = routes.home.show;
      },
      goConsult() {
        window.location.href = routes.order.consult;
      },
      goHistory() {
        window.location.href = routes.order.history;
      },
      checkFromOrderDetails() {
            const urlParams = new URLSearchParams(window.location.search);
            this.fromOrderDetails = urlParams.has('from') && urlParams.get('from') === 'orderDetails';

            const path = window.location.pathname;
            if (path.includes('orderconfirmation')) {
                this.fromOrderDetails = true;
            }
        },
    },
    mounted(){
        this.checkFromOrderDetails();
    }
  };
  </script>
  
  <style scoped lang="scss">
  .purpleButton {
    color: #55368B !important;
    font-size: 14px;
  }
  .greyButton{
    color: #555555 !important;
    font-size: 14px;
  }

  @media (max-width: 800px){
    .greyButton, .purpleButton{
        font-size: 12px;
    }
  }

  </style>
  