const amplitude = require("@amplitude/analytics-browser");
const amplitudeHelpers = require("./amplitudeHelpers");

function checkIfSearchEventDataIsValid({
    originName,
    originUF,
    destinationName,
    destinationUF,
    departureDate,
    isStudent,
}) {
    if (
        !originName ||
        !originUF ||
        !destinationName ||
        !destinationUF ||
        !departureDate ||
        departureDate === "undefined-undefined-" ||
        typeof isStudent !== "boolean"
    ) {
        return false;
    }

    return true;
}

function seatMapViewEvent() {
    try {
        const price = $(".ticket__card.selected #ticket-price").data("price");
        const availableSeats = $(".ticket__card.selected #ticket-price").data("poltronaslivres");
        const busClass = $(".ticket__card.selected .js-ticket-content").data("classe").toLowerCase();
        const occupiedSeats = +$("#amplitudeData").data("occupiedseats");
        const totalSeats = +$("#amplitudeData").data("totalseats");
        const openBusMapEvent = {
            event_type: "Mapa De Assentos Visualizado",
            event_properties: {
                assento_classe: busClass,
                assentos_livres: availableSeats,
                assentos_ocupados: occupiedSeats,
                assentos_total: totalSeats,
                valor_passagem_trecho: price,
            },
        };
        amplitude.track(openBusMapEvent);
    } catch (error) {
        console.error(
            "%cerror | Mapa De Assentos Visualizado",
            "color: red",
            error
        );
    }
}

function searchEvent() {
    try {
        const originName = $(".cities__origin").val().split(" - ")[0];
        const originUF = $(".cities__origin").val().split(" - ")[1];
        const destinationName = $(".cities__destination").val().split(" - ")[0];
        const destinationUF = $(".cities__destination").val().split(" - ")[1];
        const departureDate = amplitudeHelpers.convertDateFormat(
            $(".dates__departure").val()
        );
        const returnDate = $(".dates__back").val()
            ? amplitudeHelpers.convertDateFormat($(".dates__back").val())
            : '';
        const isStudent = $("#isStudent").prop("checked");
        // Pesquisa para PCD atualmente só é realizada através de sites institucionais, não sendo opção na busca pelo site da Mobifacil.
        // const isPCD = $('#isPCD').prop('checked') || queryString.isPCD == true || queryString.isPCD == 'true';
        const tripType = returnDate ? "Ida e Volta" : "Ida";

        const isEventValid = checkIfSearchEventDataIsValid({
            originName,
            originUF,
            destinationName,
            destinationUF,
            departureDate,
            isStudent,
        })

        if (!isEventValid) {
            return;
        }

        const searchEvent = {
            event_type: "Busca Solicitada",
            event_properties: {
                cidade_origem: originName,
                uf_origem: originUF,
                cidade_destino: destinationName,
                uf_destino: destinationUF,
                data_partida: departureDate,
                data_retorno: returnDate,
                confirmacao_estudante: isStudent,
                tipo_viagem: tripType,
            },
        };
        amplitude.track(searchEvent);
    } catch (error) {
        console.error("%cerror | Busca Solicitada", "color: red", error);
    }
}

function minicartView() {
    try {
        const path = window.location.pathname;
        const tripTotalValue = parseFloat(
            $(".minicart-footer-sumary-total-value").text().trim().replace("R$", "").replace(",", ".")
        );

        const viagens = [];
        $(".minicart-tripBox:visible").each(function () {
            let cityOrigin = $(this).find(".minicart-origin").text().trim().split("-")[0].trim();
            let stateOrigin = $(this).find(".minicart-origin").text().trim().split("-")[1].trim();
            let cityDestination = $(this).find(".minicart-destination").text().trim().split("-")[0].trim();
            let stateDestination = $(this).find(".minicart-destination").text().trim().split("-")[1].trim();
            let departureDate;
            let returnDate;

            $(this).find(".minicart-card-header").each(function () {
                if ($(this).is(":visible")) {
                    let title = $(this).find(".card-title").text().trim();
                    let dateText = $(this).find("p").text().trim();
                    let regex = /\d{2}\/\d{2}/;
                    let matches = dateText.match(regex);
                    if (matches) {
                        let date = matches[0];
                        let parts = date.split("/");
                        let currentYear = new Date().getFullYear();
                        let formattedDate = `${currentYear}-${parts[1]}-${parts[0]}`;

                        if (title.toLowerCase().includes("ida")) {
                            departureDate = formattedDate;
                        } else if (title.toLowerCase().includes("volta")) {
                            returnDate = formattedDate;
                        }
                    }
                }
            });

            let seats = [];
            $(this).find(".card-seat-info:visible").each(function () {
                let seatNumberText = $(this).find(".card-seat-number span").text().trim();
                let seatNumber = parseInt(seatNumberText, 10);
                if (!isNaN(seatNumber)) {
                    seats.push(seatNumber);
                }
            });

            viagens.push({
                cidade_origem: cityOrigin,
                uf_origem: stateOrigin,
                cidade_destino: cityDestination,
                uf_destino: stateDestination,
                data_partida: departureDate,
                data_retorno: returnDate,
                assento_numero: seats,
            });
        });

        const mincartOpenEvent = {
            event_type: 'Abertura de Carrinho',
            event_properties: {
                nome_pagina: path,
                status_login: !!sessionStorage.getItem('loginChecked'),
                viagens: viagens,
                valor_total_compra: tripTotalValue,
            },
        };
        amplitude.track(mincartOpenEvent);
    } catch (error) {
        console.error("%cerror | Carrinho Visualizado", "color: red", error);
    }
}

function tripNotFound(){

    try {
        var origin = $('.originValue').text().trim();
        var origin = $('.destinationValue').text().trim();
    
        var origemArray = origin.split(' - ');
        var destinoArray = origin.split(' - ');
    
        var cidade_origem = origemArray[0];    
        var uf_origem = origemArray[1];        
        var cidade_destino = destinoArray[0];  
        var uf_destino = destinoArray[1];      
    
        var urlParams = new URLSearchParams(window.location.search);
        var data = urlParams.get('date');
    
        var dataArray = data.split('-');
        var dia = dataArray[0];
        var mes = dataArray[1];
        var ano = dataArray[2];
    
        var formattedDate = `${mes}/${dia}/${ano}`;
    
        const tripNotFound = {
            event_type: "Trecho nao encontrado",
            event_properties: {
                cidade_origem: cidade_origem,
                uf_origem: uf_origem,
                cidade_destino: cidade_destino,
                uf_destino: uf_destino,
                data_partida: formattedDate,
                status_login: !!sessionStorage.getItem('loginChecked')
            },
        };
        amplitude.track(tripNotFound);
    } catch (error) {
        console.error("%cerror | Trecho Nao Encontrado", "color: red", error);
    }
}



module.exports = {
    seatMapViewEvent: seatMapViewEvent,
    searchEvent: searchEvent,
    minicartView: minicartView,
    tripNotFound: tripNotFound,
};
