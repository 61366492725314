<template lang="html">
    <modal modalId="login-modal" size="md" :centerTitle="true">
        <template v-slot:title class="center">
            <img :src="`${staticPath}images/logo-branco.svg`" :alt="resources.base.mobifacil"
                :title="resources.base.mobifacil" width="130" height="32" />
        </template>
        <template v-slot:body>
            <p class="title mb-5">{{ `${resources.base.hello} ${resources.base.login}` }}</p>

            <div class="row">

                <div class="col-12 mb-3">
                    <div v-if="errorMessages" class="alert alert-danger">
                        {{ errorMessages }}
                    </div>
                    <input-vue id="login-email" type="email"
                    :placeholder="resources.forms.email" v-model="userEmail"
                    :attributes="[{ name: 'required', value: 'required' }, { name: 'orange-id', value: 'login-email' }]"
                     />
                </div>
                <div class="col-12 mb-5">
                    <input-vue id="login-password" type="password"
                        placeholder="Senha" v-model="userPassword"
                        :attributes="[{ name: 'required', value: 'required' }, { name: 'orange-id', value: 'login-password' }]"
                        :isPassword="true" />
                    <button-vue
                        :label="resources.base.forgotPassword"
                        className="ghost mob-color--primary mob-text--large sm position-absolute text-decoratation-none"
                        @click="showForgetPasswordModal"  v-if="!hideRegister" />
                </div>
                <div class="col-12 mb-3">
                <div class="button-wrapper">
                    <button-vue
                        label="Entrar" :isLoad="load"
                        className="w-100"
                        @click="submitLogin"
                        :attributes="[{ name: 'disabled', value: !userEmail || !userPassword }, { name: 'orange-id', value: 'login-submit' }]" />
                </div>
                <div class="col-12 mt-5 text-center" v-if="!hideRegister">
                        <p class="mob-subtitle--small">{{ resources.identification.notRegisterYet }}</p>
                    <button-vue
                        class="ghost mob-color--primary mob-text--large sm h-100"
                        @click="openRegistrationModal"
                        :label="resources.base.signup"
                        :attributes="[{ name: 'orange-id', value: 'checkoutRegisterButton' }]"
                    />
                </div>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '../ui/Modal.vue';
import routes from '../../api/routes';
import { mapGetters, mapState } from 'vuex';
import InputVue from '../ui/InputVue.vue';
import ButtonVue from '../ui/ButtonVue.vue';

export default {
    props: ['hideRegister'],
    components: {
        'modal': Modal,
        'input-vue': InputVue,
        'button-vue': ButtonVue,
    },
    computed: {
        ...mapGetters('account', ['email']),
        ...mapGetters('checkout', ['resources', 'csrf']),

        ...mapState({
            csrf: (state) => state.checkoutOnePage.csrf,
        }),
        csrfToken() {
            return this.csrf?.token || this.getCsrfTokenFromDOM();
        }
    },
    data() {
        return {
            staticPath: routes.urlStatic,
            userEmail: '',
            userPassword: '',
            load: false,
            errorMessages: null,
            csrf_token: '',
        };
    },
    methods: {
        getCsrfTokenFromDOM() {
            const csrfInput = document.querySelector('input[name="csrf_token"]');
            if (csrfInput) {
                return csrfInput.value;
            } else {
                console.error('CSRF token não encontrado no DOM.');
                return '';
            }
        },
        showForgetPasswordModal() {
            $('#login-modal').modal('hide');
            $('#forgetPasswordModal').modal('show');
        },
        openRegistrationModal() {
            $('#login-modal').modal('hide');
            $('#register-modal').modal('show');
        },
        async submitLogin() {
            this.load = true;
            try {
                const emptyEmail = this.userEmail === '';
                const emptyPassword = this.userPassword === '';

                if(emptyEmail || emptyPassword) {
                    this.errorMessages = 'Por favor, preencha todos os campos.';
                    return;
                }

                const csrfToken = this.csrfToken;

                const result = await this.$store.dispatch('account/login', {
                    loginEmail: this.userEmail,
                    loginPassword: this.userPassword,
                    csrf_token: csrfToken,
                });

                if (result.success) {
                    sessionStorage.setItem('loginChecked', 'true');
                    location.reload();
                } else {
                    this.errorMessages = result.error;
                }
            } catch (error) {
                console.error('Erro no login:', error);
                this.errorMessages = 'Erro ao tentar fazer login. Tente novamente mais tarde.';
            } finally {
                this.load = false;
            }
        }
    },
    mounted() {
        if (!this.csrf || !this.csrf.token) {
            this.getCsrfTokenFromDOM();
        }
    }
}
</script>

<style scoped lang="scss">
#login-modal {
    .title {
        color: #333333;
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
    }

    .position-absolute{
        top: 50px;
    }

    .alert{
        font-size: 14px;
        line-height: 20px;
    }
}
</style>
