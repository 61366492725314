'use strict';

const iconNotify = $('.icon-notify').attr('src');
const badgeNotify = $('link[rel="icon"]').prop('href');
const swPath = $('.icon-notify').data('sw-path');

function urlBase64ToUint8Array(base64String) {
    const paddingLength = (4 - (base64String.length % 4)) % 4;
    const padding = '='.repeat(paddingLength);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; i += 1) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}


function registerServiceWorker() {
    return navigator.serviceWorker
        .register(swPath)
        .then(function (registration) {
            return registration;
        })
        .catch(function (err) {
            console.error('Unable to register service worker.', err);
        });
}

function askPermission() {
    return new Promise(function (resolve, reject) {
        const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
        });

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    }).then(function (permissionResult) {
        if (permissionResult !== 'granted') {
            throw new Error("We weren't granted permission.");
        }
    });
}

function subscribeUserToPush() {
    return navigator.serviceWorker
        .register(swPath)
        .then(function (registration) {
            const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                    'BIAJKfbKTZ_IAppGGmPAiOBx9sfri8FJGNkmeT1DE4VFYDiiVdPKdPVnWV72Zdz0altmY9sIJSEkqIhq9U22k4Y'
                )
            };

            return registration.pushManager.subscribe(subscribeOptions);
        })
        .then(function (pushSubscription) {
            const url = $('.link-notify').data('link-subscribe');
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    endpoint: pushSubscription,
                    swPath
                })
            });
            return pushSubscription;
        });
}

async function init() {
    console.info('Inicializando sw...');
    await registerServiceWorker();
}

async function initNotifyer() {
    await askPermission();
    await subscribeUserToPush();
}

async function notify({
    title, body, icon, actions, image
}) {
    navigator.serviceWorker.getRegistration(swPath).then(function (reg) {
        reg.showNotification(title, {
            body,
            icon: icon || iconNotify || null,
            badge: badgeNotify,
            vibrate: [200, 100, 300, 100, 600],
            image,
            actions
        });
    });
}

module.exports = {
    init: init,
    initNotifyer: initNotifyer,
    notify: notify
};
