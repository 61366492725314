'use strict';

var formValidation = require('../components/formValidation');
var createErrorNotification = require('../components/errorNotification');
var placingLabels = require('../components/placingLabels');
var einsteinMkt = require('../einsteinMkt/einsteinMkt');
var queryString = require('../utils/queryString');
var toggleModal = require('../components/toggleModal');
const amplitudeTriggersAccount = require('../amplitude/amplitudeTriggersAccount');

module.exports = {
    login: function () {
        $('form.login').submit(function (e) {
            e.preventDefault();

            var form = $(this);
            var url = form.attr('action');

            var button = form.find('button[type="submit"]');

            $(button).attr('disabled', true);

            $.spinner().start();
            $('form.login').trigger('login:submit', e);

            $(this).find('.btn').addClass('disabled');

            var loginData = form.serializeArray();
            const isLoginRepeatOrder = $('form.login').data('lastorder') === true;

            if (window.location.queryString.repeatOrder || isLoginRepeatOrder) {
                loginData.push({
                    name: 'repeatOrder',
                    value: true
                });
            }

            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: loginData,
                success: function (data) {
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                        form.find('.btn').removeClass('disabled');

                        if (data.doubleSession) {
                            $('.doubleSession').removeClass('d-none');
                            $('.login').addClass('d-none');
                            $('.login__label').addClass('d-none');
                        }
                        $(button).removeAttr('disabled');
                    } else {
                        $(window).trigger('reservamos:identify', data.reservamosData);

                        const isLoginWifi = Object.keys(data.redirectUrl).length > 0 ? data.redirectUrl.includes('prosseguir') : false;
                        const goCheckout = $('form.login').data('is-finished') === true;
                        const goHome = $('.login').attr('action').includes('goHome=true');
                        const goHistory = data.queryString == 'gohistory=true';
                        if (isLoginWifi || isLoginRepeatOrder || goHome || data.incompleteRegistration) {
                            location.href = data.redirectUrl;
                        } else if (goCheckout) {
                            window.location.href = 'https://' + window.location.host + '/s/Mobifacil/cart';
                        } else if (goHistory) {
                            location.href = data.redirectHistory;
                        } else {
                            location.reload();
                        }
                        $('form.login').trigger('login:success', data);
                        einsteinMkt.methods.pushCustomer($('#login-form-email').val());
                    }
                    $.spinner().stop();
                },
                error: function (dataErr) {
                    form.find('.btn').removeClass('disabled');

                    if (dataErr.responseJSON.redirectUrl) {
                        window.location.href = dataErr.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', dataErr);
                        $(button).removeAttr('disabled');
                        $.spinner().stop();
                    }
                }
            });
            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $.spinner().start();
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    $.spinner().stop();
                }
            });
            return false;
        });
    },

    updateRegistrationData: function () {
        $('.update-registration-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $.spinner().start();
            $('.update-registration-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else if (data.emailNotFound) {
                        $('.email-form').hide();
                        $('.cpf-form').show();
                    } else {
                        const homeUrl = $('.logo-home').attr('href');

                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#submitEmailButton').text(data.buttonText)
                                .attr({
                                    type: 'button',
                                    'data-dismiss': 'modal',
                                    onclick: `window.location.href='${homeUrl}'`
                                });
                        } else {
                            $('.send-email-btn').empty()
                                .html('<a href="' +
                                    `${homeUrl}` +
                                    '" class="btn btn-primary btn-block">' +
                                    data.buttonText + '</a>'
                                );
                        }
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else if (data.emailNotFound) {
                        $('.email-form').hide();
                        $('.cpf-form').show();
                    } else {
                        $('.request-password-title').text(data.receivedMsgHeading);
                        $('.request-password-body').empty()
                            .append('<p>' + data.receivedMsgBody + '</p>');

                        amplitudeTriggersAccount.changePassword();

                        $('#submitEmailButton').text('Continuar Navegando').on('click', function () {
                            toggleModal.hide('#requestPasswordResetModal');
                            toggleModal.show('#login-modal');
                            if (window.innerWidth < 800) {
                                $('.modal-background').remove()
                }
                        });
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPasswordByCPF: function () {
        $('.reset-password-form-cpf').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $.spinner().start();
            $('.reset-password-form-cpf').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else if (data.cpfNotFound) {
                        $('.cpf-form').hide();
                        $('.cpf-form-failed').show();
                    } else {
                        var email = data.email;
                        let hide = email.split('@')[0].length - 3; // <-- number of characters to show
                        var r = new RegExp('.{' + hide + '}@', 'g');
                        email = email.replace(r, '***@');
                        $('.returned-email').text(email);
                        $('.cpf-form').hide();
                        $('.cpf-form-success').show();
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
            return false;
        });
    },

    maskCpf: function () {
        $('#reset-password-cpf').on('keyup', function () {
            var mask = require('../components/cleave');
            mask.handleCPF('#reset-password-cpf');
        });
    },

    clearResetForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#reset-password-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    },

    onReady: function () {
        $(document).ready(function () {
            placingLabels.start();
            queryString();

            if (location.queryString.resetPassword) {
                $('#password-reset').trigger('click');
            }

            if (window.location.queryString.loginModal) {
                toggleModal.show('#login-modal');
                if (window.innerWidth < 800) {
                    $('.modal-background').remove()
    }
            }
        });
    },

    handleNewResetPasswordBehavior: function () {
        $('#password-reset').on('click', function () {
            toggleModal.hide('#login-modal');
            if (location.queryString.resetPassword) {
                $('#password-reset').trigger('click');
            }
        });

        $('#login-form-email, #login-form-password').focusin(function () {
            $('#login-modal').find('.alert-danger').remove();
        });
    },

    triggerClickChatbotHelp: function () {
        var url = $('.password__container').data('url');

        $('#requestPasswordResetModal .close').click(function () {
            toggleModal.show('#login-modal');
            if (window.innerWidth < 800) {
                $('.modal-background').remove()
}
        });

        $('body').on('click', '.js-linkChatbot', function (ev) {
            ev.preventDefault();

            if (!$(this).is('.linkChatbot__modal')) {
                window.location = `${url}#openchat`;
            }
            $('.helpButtonEnabled').trigger('click');
            $('#requestPasswordResetModal .close').trigger('click');
        });
    },

    getOpenChat: function () {
        $(window).on('load', function () {
            if (window.location.hash == '#openchat') {
                $('.helpButtonEnabled').trigger('click');
            }
        });
    },

    handleLoginModalForm: function () {
        $('.login-header-btn').on('click', function () {
            let url = $(this).data('link');

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    if (data.isLogged) {
                        $('.searchTicketsLoading__subTitle').html(
                            '<span class="searchTicketsLoading__subTitle--orangeColor">' +
                            'Por favor, aguarde ' +
                            '</span>' +
                            'Enquanto buscamos seus dados.' +
                            '</span>'
                        );
                        $('body').trigger('busLoading:init');
                        window.location.reload();
                    } else {
                        toggleModal.show('#login-modal');
                        if (window.innerWidth < 800) {
                            $('.modal-background').remove()
            }
                    }
                },
                error: function () {
                    toggleModal.show('#login-modal');
                    if (window.innerWidth < 800) {
                        $('.modal-background').remove()
        }
                }
            });
        });

        $('.login-modal').on('click', function () {
            toggleModal.show('#login-modal');
            if (window.innerWidth < 800) {
                $('.modal-background').remove()
}
            $('form.login').attr('action', $('form.login').attr('action') + '?gohistory=true');
        });

        $('.show__password').on('click', function () {
            var $inputPassword = $(this).parent().find('input');

            if ($inputPassword.attr('type') === 'password') {
                $inputPassword.attr('type', 'text');
                $(this).addClass('hide__password');
            } else {
                $inputPassword.attr('type', 'password');
                $(this).removeClass('hide__password');
            }
        });
    },

    handleRegisterModalForm: function () {
        $('.show-register').on('click', function () {
            toggleModal.show('#modal-register');
            toggleModal.hide('#requestPasswordResetModal');
        });

        $('#registration-form-password').on('input', function () {
            let currentVal = this.value;

            const getValidation = (value, type) => {
                const validation = {
                    letters: () => /[a-zA-Z]/.test(value),
                    size: () => value.length >= 8,
                    numbers: () => /\d/.test(value),
                    special: () => /[!@#$%^&*(),.?":{}|<>]/.test(value)
                };

                return validation[type]();
            };

            const checkedElement = elementClass => $('.password-requirements').find(elementClass);

            const changeElementStyle = (mainElem, childElem, validation) => {
                if (getValidation(currentVal, validation)) {
                    mainElem.css('display', 'inline');
                    childElem.css('display', 'none');
                } else {
                    mainElem.css('display', 'none');
                    childElem.css('display', 'inline');
                }
            };

            const checkAllElements = (one, two, three, four) => {
                if (one.css('display') == 'inline' && two.css('display') == 'inline' && three.css('display') == 'inline' && four.css('display') == 'inline') {
                    $('#registration-form-password').attr('data-is-correct', 'true');
                } else {
                    $('#registration-form-password').attr('data-is-correct', 'false');
                }
            };

            changeElementStyle(checkedElement('.one'), $('.one-wrong'), 'letters');
            changeElementStyle(checkedElement('.two'), $('.two-wrong'), 'size');
            changeElementStyle(checkedElement('.three'), $('.three-wrong'), 'numbers');
            changeElementStyle(checkedElement('.four'), $('.four-wrong'), 'special');

            checkAllElements($('.one'), $('.two'), $('.three'), $('.four'));
        });

        $('.form-control, .accept-term').on('input', function () {
            const validDocument = () => {
                let cpf = !!$('#registration-form-cpffield').val() && $('#registration-form-cpffield').css('display') == 'block';
                let passport = !!$('#registration-form-passportfield').val() && $('#registration-form-passportfield').css('display') == 'block';
                return !!(cpf || passport);
            };

            const validContact = !!$('#registration-form-fname').val() && !!$('#registration-form-email').val() && !!$('#registration-form-cellphonewithddd').val();
            const validPassword = $('#registration-form-password').attr('data-is-correct') == 'true';
            const validTerms = $('#acceptTerm').prop('checked') == true;
            const validCPF = validDocument() && ($('#registration-form-cpffield').val().length > 13);
            const validAll = validCPF && validContact && validPassword && validTerms;

            $('#registerSubmitButton').prop('disabled', !validAll);
        });

        $('#registerSubmitButton, .accept-term').on('mouseenter mouseleave input', function () {
            const termsCheckbox = $('#acceptTerm').prop('checked');
            if (termsCheckbox) {
                $('.terms-error').removeClass('show');
            } else {
                $('.terms-error').addClass('show');
            }
        });
    },

    loadCart: function () {
        if ($('.js-loadCartCounter').val()) {
            setTimeout(function () {
                const currentURL = window.location.href.split('?')[0];
                const counter = (+$('.js-loadCartCounter').val()) + 1;
                const updatedURL = currentURL + '?loadCart=' + counter;

                window.location.href = updatedURL;
            }, 5000);
        }
    },
    refreshSession: function () {
        $('.refresh-session').on('click', function () {
            $('body').trigger('busLoading:init');
            $('.doubleSession').addClass('d-none');
            $('.login').removeClass('d-none');
            $('.login__label').removeClass('d-none');
            $('.login').attr('action', $('.login').attr('action') + '?goHome=true');
        });
    },
    logout: function () {
        $('.js-logoutBtn').on('click', function () {
            $('body').trigger('busLoading:init');
        });
    },
    csrfPageModalOpener: function () {
        if ($('.js-csrf-error').length) {
            toggleModal.show('#login-modal');
            if (window.innerWidth < 800) {
                $('.modal-background').remove()
}
            $('.modal-header .close').addClass('d-none');
            $('#login-modal').unbind('click');
            $('.doubleSession').removeClass('d-none');
            $('.login').addClass('d-none');
            $('.login__label').addClass('d-none');
        }
    }

};
