import routes from '../../api/routes';
import checkoutApi from '../../api/checkoutOnePage';

const state = () => ({
    activeInsurance: false,
    loadData: true,
    loadSubmitPayment: false,
    loadSubmitPaymentPix: false,
    loadPlaceOrder: false,
    loadPooling: false,
    totals: {},
    forms: {
        billingForm: {},
    },
    expirationYears: [],
    currentStage: {},
    changeStep: null,
    csrf: {
        token: '',
        tokenName: '',
    },
    resources:  {
        base: {
            title: '',
            description: '',
            back: '',
        },
        steppers: {
            identify:  '',
            passengers:  '',
            payment:  '',
        },
        countDown: {
            textLineOne:  '',
            textLineTwo:  '',
            modalTitle:  '',
            modalMessage:  '',
            modalButton:  '',
        },
        sidebar: {
            summary: {}
        },
        forms: {},
        identification: {},
        passengers: {},
        payment: {
            errors: {},
            insurance: {
                info: {},
            },
        },
    },
    savedPassengers: {},
    trips: {},
    expirationYears: [],
    totalSeats: 0,
    installmentOptions: [],
    checkoutTimeout: 0,
    paymentStarted: false,
    isMobile: false,
    tripsPassengers: {},
    validatedPassengers: {},
    showBusAnimation: false,
    passengersFilled: false,
    passengersStepCompleted: false,
    pixData: {
        expiracao: 10000,
        QRCopyAndPaste: '',
    },
    couponToRemove: null,
    error: {
        show: false,
        message: '',
        title: '',
        timer: false,
        goHome: false,
    },
    showPassengersErrors: false,
    showModalPassengersErrors: {
        show: false,
        message: ''
    },
    leftTriesCard: 3,
    triesError: {
        show: false,
        title: '',
        message: '',
        showPix: false,
        showCRC: false,
    },
    device: '',
    checkoutBegin: {},
    disableCreditCard: false,
    insuranceInfo: '',
    insuranceFooter: '',
    paymentMethod: '',
    reservamosPassengers: [],
    reservamosData: {},
});

const getters = {
    activeInsurance: (state) => {
        return state.activeInsurance;
    },
    loadData: (state) => {
        return state.loadData;
    },
    totals:(state) => {
        return state.totals;
    },
    forms:(state) => {
        return state.forms;
    },
    expirationYears:(state) => {
        return state.expirationYears;
    },
    currentStage:(state) => {
        return state.currentStage;
    },
    changeStep: (state) => {
        return state.changeStep;
    },
    csrf: (state) => {
        return state.csrf;
    },
    resources: (state) => {
        return state.resources;
    },
    savedPassengers: (state) => {
        return state.savedPassengers;
    },
    trips: (state) => {
        return state.trips;
    },
    expirationYears: (state) => {
        return state.expirationYears;
    },
    totalSeats: (state) => {
        return state.totalSeats;
    },
    installmentOptions: (state) => {
        return state.installmentOptions;
    },
    checkoutTimeout: (state) => {
        return state.checkoutTimeout;
    },
    isMobile: (state) => {
        return state.isMobile;
    },
    hasReturnTrip: (state) => {
        return state.trips.some(tripsArray => (
            tripsArray.some(trip => trip.tripSection === "VOLTA")
        ));
    },
    showBusAnimation: (state) => {
        return state.showBusAnimation;
    },
    tripsPassengers: (state) => {
        return state.tripsPassengers;
    },
    loadSubmitPayment: (state) => {
        return state.loadSubmitPayment;
    },
    loadSubmitPaymentPix: (state) => {
        return state.loadSubmitPaymentPix;
    },
    loadPlaceOrder: (state) => {
        return state.loadPlaceOrder;
    },
    loadPooling: (state) => {
        return state.loadPooling;
    },
    pixData: (state) => {
        return state.pixData;
    },
    paymentStarted: (state) => {
        return state.paymentStarted;
    },
    error: (state) => {
        return state.error;
    },
    leftTriesCard: (state) => {
        return state.leftTriesCard;
    },
    triesError: (state) => {
        return state.triesError;
    },
    device: (state) => {
        return state.device;
    },
    checkoutBegin: (state) => {
        return state.checkoutBegin;
    },
    disableCreditCard: (state) => {
        return state.disableCreditCard;
    },
    insuranceInfo: (state) => {
        return state.insuranceInfo;
    },
    insuranceFooter: (state) => {
        return state.insuranceFooter;
    },
    paymentMethod: (state) => {
        return state.paymentMethod;
    },
    reservamosPassengers: (state) => {
        return state.reservamosPassengers;
    },
    reservamosData: (state) => {
        return state.reservamosData;
    },
};

const actions = {
    async getCheckoutData({ commit, dispatch, rootState }, data) {
        commit('setLoadData', true);
        let req = checkoutApi.getCheckoutDataReq(
            routes.checkout.data,
            data,
            rootState
        );

        try {
            const response = await fetch(req.url, req.options);
            const data = await response.json();
            let isLogged = false;

            if(!data.error){
                const device = data.trips[0][0].lineItems[0].device;
                commit('setData', data);
                commit('setTotals', data.totals);
                commit('account/setCustomer', data.customer, { root: true });
                commit('setLoadData', false);
                $('.skeletonCheckoutFluido').remove();
                commit('setDevice', device);
                if (data.customer.userType === 'logged') {
                    commit('account/setEmail', data.customer.profile.email, { root: true });
                    commit('account/setIdentifyStepCompleted', true, { root: true });
                    commit('account/setUserTypeRegister', 'REGISTERED', { root: true });
                    isLogged = true;
                }
                commit('account/setIsLogged', isLogged, { root: true });
                commit('setDisableCreditCard', data.disableCreditCard);

            }

            let docType = '';
            if (rootState.account && rootState.account.customer && rootState.account.customer.profile) {
                docType = rootState.account.customer.profile.docType || '';
            }

            const eventProperties = {
                trips: data.trips,
                totalPrice: data.totals.totalPrice.formatted,
                docType: docType,
            };
            $('body').trigger('amplitude:checkoutIniciado', eventProperties);

            commit('setCheckoutBegin', JSON.stringify(eventProperties));

            return data;
        } catch (error) {
            console.error('Fetch error getCheckoutData: ', error);
        }
    },
    async addCouponCode({ commit, dispatch, rootState }, data) {
        let req = checkoutApi.addCouponCode(
            routes.cart.addCoupon,
            data,
            rootState
        );

        try {
            const response = await fetch(req.url, req.options);
            const data = await response.json();

            if(data.success){
                commit('setTotals', data.totals);

                const discount = data.totals.discounts[data.totals.discounts.length - 1];
                const totalDesconto = data.totals.discounts.reduce((sum, discount) => sum + discount.value, 0);
                const eventPropertiesCupomAplicado = {
                    cupom_codigo: discount.name,
                    valor_cupom_desconto: discount.value,
                    valor_total_compra: parseFloat(data.totals.totalPrice.value.toFixed(2)),
                    valor_total_desconto: totalDesconto
                };

                $('body').trigger('amplitude:cupomAplicado', eventPropertiesCupomAplicado);

                return data;
            } else {
                throw new Error(data.error.message || 'Erro ao aplicar o cupom');
            }
        } catch (error) {
            console.error('Fetch error addCouponCode: ', error);
            throw error;
        }
    },
    async handleInsurance({ commit, dispatch, rootState }, data) {
        let req = checkoutApi.handleInsuranceReq(
            routes.cart.insurance,
            data,
            rootState
        );

        try {

            const response = await fetch(req.url, req.options);
            const data = await response.json();

            if(data.success){
                commit('setTotals', data.totals);

                const eventPropertiesSeguroViagem = {
                    checkoutData: rootState.checkoutOnePage
                };

                $('body').trigger('amplitude:seguroViagem', eventPropertiesSeguroViagem);
            } else {
                throw new Error(data.error.message || 'Erro ao aplicar/remover o seguro');
            }

        } catch (error) {
            console.error('Fetch error addCouponCode: ', error);
            throw error;
        }
    },

    async updateActiveInsurance({ commit, dispatch, state }, data) {
        commit('setActiveInsurance', data );

        var body = {
            insurance: data,
            billingForm: state.forms.billingForm
        };

        await dispatch('handleInsurance', body);
    },
    async removeCouponCode({ commit, dispatch, rootState }, couponCode) {
        let req = checkoutApi.removeCouponCode(
            routes.cart.removeCoupon,
            couponCode,
            rootState
        );

        try {
            const response = await fetch(req.url, req.options);
            const data = await response.json();

            if(!data.error){
                commit('setTotals', data.totals);
            }

            return data;
        } catch (error) {
            console.error('Fetch error getCheckoutData: ', error);
        }
    },
    async verifyPassengers({ commit, rootState }, data) {
        try {
            let req = checkoutApi.verifyPassengers(
                routes.checkout.verifyPassengers,
                data,
                rootState
            );
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();

            if (responseData.success) {
                commit('setPassengersStepCompleted', true)
                commit('setValidatedPassengers', data)

            let foundUserPassenger = false;
            let foundStudent = false;
            let isPCD = false;
            let documentType = null;

            data.passengers.forEach(passenger => {
                if (passenger.userPassenger === true) {
                    foundUserPassenger = true;
                    isPCD = passenger.isPCD;
                    documentType = passenger.documentType;
                }
            });

            if (!foundUserPassenger) {
                data.passengers.forEach(passenger => {
                    if (passenger.isStudent === true) {
                        foundStudent = true;
                        isPCD = passenger.isPCD;
                        documentType = passenger.documentType;
                    }
                });
            }

            const eventPropertiesPassengerIdentification = {
                confirmacao_estudante: foundStudent,
                confirmacao_pcd: isPCD,
                confirmacao_usuario_passageiro: foundUserPassenger,
                tipo_documento: documentType,
            }

            $('body').trigger('amplitude:passengerIdentification', eventPropertiesPassengerIdentification);

                return responseData;
            } else if (responseData.error.message.general) {
                commit('setShowModalPassengersErrors', { show: true, message: responseData.error.message.general })
                return { error: true }
            }
        } catch (error) {
            console.error('[checkout][verifyPassengers] error', error);
        }
    },

    async pixSubmitPayment({ commit, dispatch, rootState }, data) {
        console.info('%c Submit Payment  ', 'background: #55368B; color: #fff');
        $(window).trigger('reservamos:getDistinctId');
        $(window).trigger('reservamos:getFingerprintId');

        const eventPropertiesSubmitPurchase = {
            metodo_de_pagamento: 'PIX',
            checkoutData: rootState.checkoutOnePage
        };
        $('body').trigger('amplitude:submitPurchase', eventPropertiesSubmitPurchase);

        const reservamosData = {
            distinctId: $('[name="reservamos_data"]').data('distinct'),
            fingerprintId: $('[name="reservamos_data"]').data('fingerprint')
        }

        data.reservamos = JSON.stringify(reservamosData)

        let req = checkoutApi.submitPaymentReq(
            routes.pix.submitPayment,
            data,
            rootState
        );
        commit('setPaymentStarted', true);
        try {
            commit('setLoadSubmitPaymentPix', true);
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();
            commit('setLoadSubmitPaymentPix', false);

            if(responseData.success){
                commit('setPixData', responseData)
                setTimeout(() => {
                    dispatch('queryPixPooling', responseData)
                }, 20000);

                if (responseData.reservamosData &&rootState.account.userTypeRegister === "NOT_REGISTERED") {
                    $(window).trigger('reservamos:identify', responseData.reservamosData);
                }

                const reservamosDataPassengers = rootState.checkoutOnePage.reservamosData;
                reservamosDataPassengers['Passengers'] = rootState.checkoutOnePage.reservamosPassengers;
                delete reservamosDataPassengers['Payment Type'];
                delete reservamosDataPassengers['Insurance'];
                delete reservamosDataPassengers['User Status'];

                delete reservamosDataPassengers['Payment Type'];
                delete reservamosDataPassengers['Insurance'];
                delete reservamosDataPassengers['User Status'];

                $(window).trigger('reservamos:passengersCreated', reservamosDataPassengers);

            } else {
                if (responseData.error.message) {
                    commit('setError', {
                        show: true,
                        message: responseData.error && responseData.error.message ? responseData.error.message : ' ',
                        title: responseData.error && responseData.error.title ? responseData.error.title : ' ',
                        timer: true,
                        goHome: responseData.error && responseData.error.redirectHome ? responseData.error.redirectHome : ' ',
                    });
                    $('#errorModal').modal('show');
                }
                throw new Error(responseData.error.message || 'Erro ao realizar o pagamento');
            }
        } catch (error) {
            commit('setLoadSubmitPaymentPix', false);
            console.error('Fetch error submitPaymentReq: ', error);
            if (error.fieldErrors) {
                return {
                    success: false,
                    fieldErrors: fieldErrors
                }
            }

            throw error;
        }
    },
    async queryPixPooling({ commit, dispatch, rootState }, data) {
        console.info('%c   Pooling started   ', 'background: #55368B; color: #fff');
        let req = checkoutApi.queryPixPoolingReq(data.continueUrl, rootState);
        try {
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();
            if (responseData.continuePooling) {
                setTimeout(() => {
                    dispatch('queryPixPooling', responseData)
                }, 20000);
            } else if (responseData.success) {
                dispatch('distribusionPooling', responseData)
            } else {
                throw new Error(responseData.error.message || 'Erro ao realizar o pagamento');
            }
        } catch (error) {
            console.error('Fetch error submitPaymentReq: ', error);
            throw error;
        }
    },
    async submitPaymentReq({ commit, dispatch, rootState }, data) {
        console.info('%c Submit Payment  ', 'background: #55368B; color: #fff');
        $(window).trigger('reservamos:getDistinctId');
        $(window).trigger('reservamos:getFingerprintId');

        const eventPropertiesSubmitPurchase = {
            metodo_de_pagamento: 'Cartão de Crédito',
            checkoutData: rootState.checkoutOnePage
        };

        $('body').trigger('amplitude:submitPurchase', eventPropertiesSubmitPurchase);

        const reservamosData = {
            distinctId: $('[name="reservamos_data"]').data('distinct'),
            fingerprintId: $('[name="reservamos_data"]').data('fingerprint')
        }

        data.reservamos = JSON.stringify(reservamosData);

        let req = checkoutApi.submitPaymentReq(
            routes.checkoutServices.submitPayment,
            data,
            rootState
        );
        commit('setPaymentStarted', true);
        try {
            commit('setLoadSubmitPayment', true);
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();
            commit('setLoadSubmitPayment', false);

            if(responseData.success){
                if (responseData.reservamosData && rootState.account.userTypeRegister === "NOT_REGISTERED") {
                    $(window).trigger('reservamos:identify', responseData.reservamosData);
                }

                const reservamosDataPassengers = rootState.checkoutOnePage.reservamosData;
                reservamosDataPassengers['Passengers'] = rootState.checkoutOnePage.reservamosPassengers;
                delete reservamosDataPassengers['Payment Type'];
                delete reservamosDataPassengers['Insurance'];
                delete reservamosDataPassengers['User Status'];

                delete reservamosDataPassengers['Payment Type'];
                delete reservamosDataPassengers['Insurance'];
                delete reservamosDataPassengers['User Status'];


                $(window).trigger('reservamos:passengersCreated', reservamosDataPassengers);

                dispatch('placeOrder', data)
                return responseData;
            } else {
                commit('setPaymentStarted', false);

                // TENTATIVAS DE ERRO
                commit('setLeftTriesCard')
                if(rootState.checkoutOnePage.leftTriesCard <= 0) {
                    commit('setTriesError', {
                        show: true,
                        title: 'Compra não finalizada!',
                        message: 'Estamos com dificuldade para finalizar sua compra. Voce pode tentar realizar o pagamento via PIX ou entre em contato pelo chat.',
                        showPix: true,
                        showCRC: true,
                    });
                    throw new Error(responseData.error.message || 'Erro ao realizar o pagamento');
                }
                // RETORNO DO ERRO DA API
                if (typeof responseData.error.message === 'string') {
                    commit('setError', {
                        show: true,
                        message: responseData.error && responseData.error.message ? responseData.error.message : ' ',
                        title: responseData.error && responseData.error.title ? responseData.error.title : ' ',
                        timer: false,
                    });
                    $('#errorModal').modal('show');
                }
                // RETORNO DE ERRO RELACIONADO A INPUTS
                if (responseData.error.fieldErrors) {
                    return {
                        success: false,
                        fieldErrors: responseData.error.fieldErrors
                    }
                }
                throw new Error(responseData.error.message || 'Erro ao realizar o pagamento');
            }
        } catch (error) {
            commit('setPaymentStarted', false);

            commit('setLoadSubmitPayment', false);

            console.error('Fetch error submitPaymentReq: ', error);
            throw error;
        }
    },
    async placeOrder({ commit, dispatch, rootState }, data) {
        console.info('%c   Place Order started   ', 'background: #55368B; color: #fff');
        let req = checkoutApi.placeOrderReq(
            routes.checkoutServices.placeOrder,
            data,
            rootState
        );

        try {
            commit('setLoadPlaceOrder', true);

            const response = await fetch(req.url, req.options);
            const responseData = await response.json();
            commit('setLoadPlaceOrder', false);

            if(responseData.errorFraudDetection|| responseData.errorCCBuyAttempts) {
                commit('setTriesError', {
                    show: true,
                    title: responseData.error && responseData.error.title ? responseData.error.title : ' ',
                    message: responseData.error && responseData.error.message ? responseData.error.message : ' ',
                    showPix: true,
                    showCRC: true,
                });
                throw new Error(responseData.error.message || 'Erro ao realizar o pagamento');
            } else if (responseData.startPooling){
                dispatch('distribusionPooling', responseData)
                return responseData;
            } else if (responseData.continueUrl) {
                window.location.replace(responseData.continueUrl)
            } else {
                commit('setLoadPlaceOrder', false);
                if (responseData.error.message) {
                    commit('setError', {
                        show: true,
                        message: responseData.error && responseData.error.message ? responseData.error.message : ' ',
                        title: responseData.error && responseData.error.title ? responseData.error.title : ' ',
                        timer: false,
                    });
                    $('#errorModal').modal('show');
                }
                throw new Error(responseData.error.message || 'Erro ao realizar o pagamento');
            }
        } catch (error) {
            console.error('Fetch error submitPaymentReq: ', error);
            commit('setLoadPlaceOrder', false);
            if (error.fieldErrors) {
                return {
                    success: false,
                    fieldErrors: fieldErrors
                }
            }
            throw error;
        }
    },
    async distribusionPooling({ commit, dispatch, rootState }, data) {
        let req = checkoutApi.distribusionPoolingReq(data.continueUrl);
        console.info('%c   Pooling started   ', 'background: #55368B; color: #fff');

        commit('setLoadPooling', true);
        try {
            const response = await fetch(req.url, req.options);
            const responseData = await response.json();

            if (responseData.continuePooling) {
                setTimeout(() => {
                    dispatch('distribusionPooling', responseData)
                }, responseData.poolingInterval);
            } else if (responseData.continueUrl) {
                window.location.replace(responseData.continueUrl)
            } else {
                commit('setLoadPooling', false);
                if (responseData.error.message) {
                    commit('setError', {
                        show: true,
                        message: responseData.error && responseData.error.message ? responseData.error.message : ' ',
                        title: responseData.error && responseData.error.title ? responseData.error.title : ' ',
                        timer: true,
                    });
                    $('#errorModal').modal('show');
                }
                throw new Error(responseData.error.message || 'Erro ao realizar o pagamento');
            }
        } catch (error) {
            console.error('Fetch error submitPaymentReq: ', error);
            throw error;
        }
    },
    async saveAbandonedCart({ rootState }) {
        const data = {
            email: rootState.account.email
        }

        let req = checkoutApi.saveAbandonedCartReq(
            routes.checkout.saveAbandonedCart,
            data,
            rootState
        );
        try {
            await fetch(req.url, req.options);
        } catch (error) {
            console.error('Fetch error submitPaymentReq: ', error);
            throw error;
        }
    },
};

const mutations = {
    setActiveInsurance(state, data) {
        state.activeInsurance = data;
    },
    setLoadData(state, data) {
        state.loadData = data;
    },
    setData(state, data) {
        state.savedPassengers = data.savedPassengers;
        state.expirationYears = data.expirationYears;
        state.totals = data.totals;
        state.trips = data.trips;
        state.totalSeats = data.totalSeats;
        state.resources = data.resources;
        state.forms = data.forms;
        state.csrf = data.csrf;
        state.checkoutTimeout = data.checkoutTimeout;
        state.insuranceInfo = data.insuranceInfo;
        state.insuranceFooter = data.insuranceFooter;
        state.reservamosData = data.reservamosData;
    },
    setTotals(state, totals) {
        state.totals = totals;
    },
    setPaymentStarted(state, data) {
        state.paymentStarted = data;
    },
    setIsMobile(state, data) {
        state.isMobile = data;
    },
    setTripsPassengers(state, data) {
        state.tripsPassengers = data;
    },
    setShowBusAnimation(state, data) {
        state.showBusAnimation = data;
    },
    setPassengersStepCompleted(state, data) {
        state.passengersStepCompleted = data;
    },
    setPassengersFilled(state, data) {
        state.passengersFilled = data;
    },
    setValidatedPassengers(state, data) {
        const allPassengersData = [];

        data.passengers.forEach((passenger, index) => {
            allPassengersData.push({
                "Passenger Name": passenger.passengerName,
                "Passenger Document Type": passenger.documentType,
                "Passenger Document Id": passenger.passengerID,
                "Passenger Birthdate": passenger.birthdate ? passenger.birthdate.split('/').reverse().join('-') : "",
                "Passenger Seat": passenger.seatNumber,
            })
        });

        state.reservamosPassengers = allPassengersData;
        state.validatedPassengers = data;
    },
    setBillingForm(state, data) {
        state.forms.billingForm = data;
    },
    setLoadPooling(state, data) {
        state.loadPooling = data;
    },
    setLoadSubmitPayment(state, data) {
        state.loadSubmitPayment = data;
    },
    setLoadSubmitPaymentPix(state, data) {
        state.loadSubmitPaymentPix = data;
    },
    setLoadPlaceOrder(state, data) {
        state.loadPlaceOrder = data;
    },
    setPixData(state, data) {
        state.pixData = data;
    },
    setQrCode(state, data) {
        state.QR = data;
    },
    setCouponToRemove(state, data) {
        state.couponToRemove = data;
    },
    setError(state, data) {
        state.error = data;
    },
    setShowPassengersErrors(state, data) {
        state.showPassengersErrors = data;
    },
    setShowModalPassengersErrors(state, data) {
        state.showModalPassengersErrors = data;
    },
    setLeftTriesCard(state, data) {
        state.leftTriesCard = state.leftTriesCard - 1;
    },
    setTriesError(state, data) {
        state.triesError = data;
    },
    setResources(state, data) {
        state.resources = data;
    },
    setDevice(state, data) {
        state.device = data;
    },
    setCheckoutBegin(state, data) {
        state.checkoutBegin = data;
    },
    setDisableCreditCard(state, data) {
        state.disableCreditCard = data;
    },
    setPaymentMethod(state, data) {
        state.paymentMethod = data;
    },
    setReservamosPassengers(state, data) {
        state.reservamosPassengers = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

