<template lang="html">
    <div>
        <Accordion id="orderSummary" variant="primary" open="true">
            <template v-slot:items>
                <AccordionItem id="orderSummary-tab" parent="orderSummary" open="true" class="px-0">
                    <template v-slot:header>
                        <p class="mb-0 mob-heading--medium mob-color--primary">
                            {{ resources.sidebar.orderSummary }}
                        </p>
                    </template>
                    <template v-slot:body>
                        <TravelData/>
                    </template>
                </AccordionItem>
                <order-summary></order-summary>
            </template>
        </Accordion>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TravelData from "./TravelData.vue";
import Accordion from '../ui/Accordion.vue';
import AccordionItem from '../ui/AccordionItem.vue';
import OrderSummary from './OrderSummary.vue';

export default {
    computed: {},
    components: {
        Accordion,
        AccordionItem,
        TravelData,
        OrderSummary
    },
    computed: {
        ...mapGetters('checkoutOnePage', ['resources']),
    },
};
</script>
