<template>
    <div class="row" @keyup.enter="submitRegister">
        <div class="col-lg-6 col-12">
            <div class="form-group formSize">
                <input-vue
                    class="formRegister--input"
                    id="register-email"
                    type="email"
                    :label="resources.forms.informEmail"
                    :placeholder="resources.forms.email"
                    v-model="form.dwfrm_profile_customer_email"
                    :attributes="[{ name: 'required', value: 'required' }, { name: 'orange-id', value: 'register-email' }, { name: 'disabled', value: true}]"
                    :errorMessage="errorMessages['dwfrm_profile_customer_email']"
                />
            </div>
            <div class="form-group formSize">
                <input-vue
                    class="formRegister--input"
                    id="register-cellphone"
                    type="text"
                    :label="resources.forms.phoneWithDDDRequired"
                    :placeholder="resources.forms.phoneWithDDDExample"
                    useMask="phone"
                    v-model="form.dwfrm_profile_customer_cellphonewithddd"
                    :attributes="[{ name: 'required', value: 'required' }, { name: 'orange-id', value: 'register-cellphone' }]"
                    :errorMessage="errorMessages['dwfrm_profile_customer_cellphonewithddd']"
                />
            </div>
        </div>
        <div class="col-lg-6 col-12">
            <div class="form-group formSize">
                <input-vue
                    class="formRegister--input"
                    id="register-fullName"
                    type="text"
                    :label="resources.forms.fullName"
                    :placeholder="resources.forms.fullNameExample"
                    v-model="form.dwfrm_profile_customer_fullname"
                    :inputValidation="inputValidation"
                    :attributes="[
                        { name: 'required', value: 'required' },
                        { name: 'orange-id', value: 'register-fullName' },
                        { name: 'pattern', value: `[A-Za-z\\s]+'` }
                    ]"
                    :errorMessage="errorMessages['dwfrm_profile_customer_fullname']"
                />
            </div>
            <div class="form-group">
                <div class="form-group d-flex justify-content-between mb-2">
                    <p class="doc-label">{{ resources.forms.documentRequired }}</p>
                    <div class="d-flex">
                        <radiobox-vue
                            id="register-docType-cpf"
                            :label="resources.forms.cpf"
                            :checked="form.dwfrm_profile_customer_document === 'cpf'"
                            name="register-docType"
                            value="cpf"
                            class="mr-3"
                            @update:modelValue="updateDocType"
                        />
                        <radiobox-vue
                            id="register-docType-passport"
                            :label="resources.forms.passport"
                            :checked="form.dwfrm_profile_customer_document === 'passport'"
                            name="register-docType"
                            value="passport"
                            @update:modelValue="updateDocType"
                        />
                    </div>
                </div>
                <input-vue
                    class="formRegister--input"
                    id="register-document"
                    type="text"
                    :useMask="form.dwfrm_profile_customer_document === 'cpf' ? 'cpf' : 'passport'"
                    v-model="documentModel"
                    :attributes="[{ name: 'required', value: 'required' }, { name: 'orange-id', value: 'register-document' }]"
                    :errorMessage="documentErrorMessage"
                />
                <div v-if="form.dwfrm_profile_customer_document === 'cpf' && errorMessages['dwfrm_profile_customer_cpffield'] === 'CPF já cadastrado!'">
                    <p class="text-danger mob-text--default">{{ resources.base.wouldYouLike }}
                        <button type="button" class="btn-link-login" @click="handleLoginClick">{{ resources.forms.loginLiteral }}</button>?
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group formSize">
                        <input-vue
                            class="formRegister--input"
                            id="register-password"
                            type="password"
                            :label="resources.forms.createPassword"
                            placeholder="******"
                            v-model="form.dwfrm_profile_login_password"
                            @input="updatePasswordValidation"
                            :attributes="[{ name: 'required', value: 'required' }, { name: 'orange-id', value: 'register-password' }]"
                            :isPassword="true"
                        />
                    </div>
                </div>
                <div class="col-12 col-lg-6 password-validator mt-2">
                    <p class="mb-2">{{ resources.forms.password.title }}</p>
                    <ul>
                        <li :class="validatePassword.hasLetter">{{ resources.forms.password.hasLetter }}</li>
                        <li :class="validatePassword.hasLength">{{ resources.forms.password.hasLength }}</li>
                        <li :class="validatePassword.hasNumber">{{ resources.forms.password.hasNumber }}</li>
                        <li :class="validatePassword.hasSpecial">{{ resources.forms.password.hasSpecial }}</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="opt-title">
                        {{ resources.forms.checkboxes.title }}
                    </p>
                    <div class="form-group mb-0">
                        <checkbox-vue
                            id="register-news"
                            class="mob-subtitle--small"
                            :label="`${resources.forms.checkboxes.check1} <b class='mob-color--primary'>${resources.base.mobifacil}</b>`"
                            v-model="form.dwfrm_profile_customer_addtonewsletter"
                            :attributes="[{ name: 'required', value: 'required' }, { name: 'orange-id', value: 'promo-news' }]"
                        />
                    </div>
                    <div class="form-group my-0">
                        <checkbox-vue
                            id="register-terms"
                            class="mob-subtitle--small"
                            :label="`
                                ${resources.forms.checkboxes.check2a}
                                <a href='https://www.mobifacil.com.br/termos-de-uso.html' class='mob-color--primary formRegister--link' target='_blank'>
                                    ${resources.forms.checkboxes.check2b}
                                </a>
                                ${resources.base.and}
                                <a href='https://www.mobifacil.com.br/politica-de-privacidade.html' class='mob-color--primary formRegister--link' target='_blank'>
                                    ${resources.forms.checkboxes.check2c}
                                </a>
                            `"
                            v-model="form.dwfrm_profile_customer_acceptTerm"
                            :attributes="[{ name: 'required', value: 'required' }, { name: 'orange-id', value: 'register-terms' }]"
                        />
                    </div>
                    <div class="form-group d-flex justify-content-center mt-3">
                        <button-vue
                            @click="submitRegister"
                            class="formRegister--button"
                            :isLoad="load"
                            :label="resources.forms.createAccount"
                            :disabled="!form.dwfrm_profile_customer_acceptTerm"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputVue from '../ui/InputVue.vue';
import CheckboxVue from '../ui/CheckboxVue.vue';
import ButtonVue from '../ui/ButtonVue.vue';
import RadioboxVue from '../ui/RadioboxVue.vue';
import { mapState } from 'vuex';

export default {
    components: {
        'input-vue': InputVue,
        'button-vue': ButtonVue,
        'checkbox-vue': CheckboxVue,
        'radiobox-vue': RadioboxVue,
    },
    props: {
        email: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            form: {
                dwfrm_profile_customer_email: this.email || '',
                dwfrm_profile_customer_cellphonewithddd: '',
                dwfrm_profile_login_password: '',
                dwfrm_profile_customer_fullname: '',
                dwfrm_profile_customer_document: 'cpf',
                dwfrm_profile_customer_passportfield: '',
                dwfrm_profile_customer_cpffield: '',
                dwfrm_profile_customer_acceptTerm: false,
                dwfrm_profile_customer_addtonewsletter: false,
                csrf_token: '',
            },
            load: false,
            errorMessages: {},
            validatePassword: {
                hasNumber: '',
                hasLetter: '',
                hasSpecial: '',
                hasLength: '',
            },
        };
    },
    computed: {
        documentErrorMessage() {
            return this.form.dwfrm_profile_customer_document === 'cpf'
                ? this.errorMessages['dwfrm_profile_customer_cpffield']
                : this.errorMessages['dwfrm_profile_customer_passportfield'];
        },
        ...mapState({
            csrf: (state) => state.checkout.csrf,
            resources: (state) => state.checkout.resources,
        }),
        documentModel: {
            get() {
                return this.form.dwfrm_profile_customer_document === 'cpf' ? this.form.dwfrm_profile_customer_cpffield : this.form.dwfrm_profile_customer_passportfield;
            },
            set(value) {
                if (this.form.dwfrm_profile_customer_document === 'cpf') {
                    this.form.dwfrm_profile_customer_cpffield = value;
                } else {
                    this.form.dwfrm_profile_customer_passportfield = value;
                }
            }
        },
        csrfToken() {
            if (this.csrf && this.csrf.token) {
                return this.csrf.token;
            }
            this.getCsrfTokenFromDOM();
            return this.form.csrf_token;
        }
    },
    watch: {
        email: function(newVal) {
            this.userEmail = newVal;
        }
    },
    methods: {
        inputValidation(event) {
            const name = event.target.value
            const regex = /^[a-zA-ZÀ-ÖØ-öø-ÿÇç\s]+$/

            if (!regex.test(name)) {
                event.target.value = name.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿÇç\s]+/g, '')
            }
        },
        getCsrfTokenFromDOM() {
            if (typeof $ !== 'undefined') {
                const csrfInput = $('input[name="csrf_token"]');
                if (csrfInput.length > 0) {
                    this.form.csrf_token = csrfInput.val();
                } else {
                    console.error('CSRF token não encontrado no DOM.');
                }
            } else {
                console.error('jQuery não está disponível.');
            }
        },
        async submitRegister() {
            this.load = true;
            try {
                this.form.csrf_token = this.csrfToken;
                const result = await this.$store.dispatch('account/submitRegistration', this.form);
                if (result.success && result.success == true) {
                    location.reload();
                } else {
                    this.errorMessages = result.fields;
                }
            } catch (error) {
                this.errorMessages = { general: error.message || this.resources.errors.fetchingData };
            } finally {
                this.load = false;
            }
        },
        updatePasswordValidation() {
            const password = this.form.dwfrm_profile_login_password;
            this.validatePassword.hasNumber = /\d/.test(password) ? 'is-valid' : 'is-invalid';
            this.validatePassword.hasLetter = /[a-zA-Z]/.test(password) ? 'is-valid' : 'is-invalid';
            this.validatePassword.hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password) ? 'is-valid' : 'is-invalid';
            this.validatePassword.hasLength = password.length >= 8 ? 'is-valid' : 'is-invalid';
        },
        updateDocType(value) {
            this.form.dwfrm_profile_customer_document = value;
        },
        handleLoginClick() {
            $("#register-modal").modal('hide');
            $("#modal-login").modal('show');
        }
    },
    mounted() {
        if (!this.csrf || !this.csrf.token) {
            this.getCsrfTokenFromDOM();
        }
    }
};
</script>

<style scoped lang="scss">
@import '../../../../scss/core/colors.scss';

.formSize{
    height: 110px;
    margin-bottom: 10px;
}

.doc-label {
    color: #333;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.opt-title {
    color: #333;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.password-validator {
    p {
        color: #333;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            color: #000;
            font-family: 'Montserrat', sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .is-valid {
            color: #00A859;
        }

        .is-invalid {
            color: #FF0000;
        }
    }
}

.text-danger.mob-text--default{
    font-size: 14px;
}

.btn-link-login{
    text-decoration: underline;
    color: #c60d0de1;
    padding: 0;
    background: none;
    border: none;
}

.formRegister--input {
    border-radius: 10px;
}

.formRegister--button{
    width: 300px;
}
</style>
