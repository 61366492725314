<template lang="html">
    <div class="card-data-form">
        <p class="mob-subtitle--medium mob-color--secondary-light-1 bd">
            {{ resources.payment.credit.cardData }}
        </p>
        <InputVue
            id="credit-card-form-number"
            name="credit-card-form-number"
            className="credit-card-form-number"
            :placeholder="resources.payment.credit.cardNumber"
            :attributes="[
                { name: 'orange-id', value: 'credit-card-form-number' },
                { name: 'autocomplete', value: 'cc-number' }
            ]"
            useMask="credit-card"
            v-model="$root.$refs.CreditPayment.form.creditCard.cardNumber"
            @input-helper="$root.$refs.CreditPayment.detectCardBrand"
            :errorMessage="$root.$refs.CreditPayment.errors.cardNumber"
        />
        <InputVue
            id="credit-card-form-name"
            name="credit-card-form-name"
            className="credit-card-form-name"
            :placeholder="resources.payment.credit.cardNOwnerName"
            :attributes="[
                { name: 'orange-id', value: 'credit-card-form-name' },
                { name: 'autocomplete', value: 'cc-name' }
            ]"
            v-model="$root.$refs.CreditPayment.form.creditCard.cardName"
            :errorMessage="$root.$refs.CreditPayment.errors.cardName"
        />
        <div class="form-row">
            <SelectVue
                class="form-group col-4"
                id="credit-card-form-month"
                name="credit-card-form-month"
                :attributes="[
                    { name: 'orange-id', value: 'credit-card-form-month'},
                ]"
                :options="monthOptions"
                v-model="$root.$refs.CreditPayment.form.creditCard.cardMonth"
                :placeholder="resources.forms.month"
                :errorMessage="$root.$refs.CreditPayment.errors.cardMonth"
            />
            <SelectVue
                class="form-group col-4"
                id="credit-card-form-year"
                name="credit-card-form-year"
                :attributes="[
                    { name: 'orange-id', value: 'credit-card-form-year'},
                ]"
                :options="expirationYears"
                v-model="$root.$refs.CreditPayment.form.creditCard.cardYear"
                :placeholder="resources.forms.year"
                :errorMessage="$root.$refs.CreditPayment.errors.cardYear"
            />
            <InputVue
                class="form-group col-4"
                id="credit-card-form-cvv"
                name="credit-card-form-cvv"
                :placeholder="resources.forms.cvv"
                :attributes="[
                    { name: 'orange-id', value: 'credit-card-form-cvv' },
                    { name: 'autocomplete', value: 'cc-csc' },
                    { name: 'maxlength', value: 4 }
                ]"
                v-model="$root.$refs.CreditPayment.form.creditCard.cardCvv"
                :errorMessage="$root.$refs.CreditPayment.errors.cardCvv"
            />
        </div>
        <SelectVue
            class="form-group"
            id="credit-card-form-installments"
            name="credit-card-form-installments"
            :attributes="[
                { name: 'orange-id', value: 'credit-card-form-installments'},
            ]"
            :options="installmentOptions"
            v-model="$root.$refs.CreditPayment.form.creditCard.installments"
            :errorMessage="$root.$refs.CreditPayment.errors.installments"
        />
        <div class="checkboxes-wrapper">
            <checkbox-vue
                id="save-card"
                :label="resources.payment.credit.saveCard"
                :checked="false"
                v-model="$root.$refs.CreditPayment.form.creditCard.saveCard"
                v-if="isLogged && userType === 'logged'"
            />
        </div>
        <ButtonVue
            v-if="isLogged && userType === 'logged'"
            type="button"
            @click="$root.$refs.CreditPayment.handleCardForm"
            className="ghost h-25 mx-auto"
            :label="resources.payment.credit.useSavedCard"
            :attributes="[
                { name: 'orange-id', value: 'credit-card-buy-saved-card' },
            ]"
        />
        <submitTerms @trigger-submit="$root.$refs.CreditPayment.submitCardForm" />
    </div>
</template>

<script>
import InputVue from '../../../ui/InputVue.vue'
import SelectVue from '../../../ui/SelectVue.vue'
import SubmitTerms from '../SubmitTerms.vue'
import ButtonVue from '../../../ui/ButtonVue.vue';
import CheckboxVue from '../../../ui/CheckboxVue.vue'
import { mapGetters } from 'vuex'

export default {

    components: {
        InputVue,
        SelectVue,
        SubmitTerms,
        CheckboxVue,
        ButtonVue,
    },
    computed: {
        ...mapGetters('checkoutOnePage', {
            expirationYears: 'expirationYears',
            totals: 'totals',
            forms: "forms",
            resources: 'resources',
        }),
        ...mapGetters('account', {
            userType: 'userType',
            savedCards: 'savedCards',
            isLogged: 'isLogged',
            userTypeRegister: 'userTypeRegister',
        }),
        installmentOptions() {
            return this.totals.installmentOptions.map(installment => ({
                value: installment.htmlValue,
                label: `${installment.htmlValue}${this.resources.payment.credit.installment} ${installment.installmentPrice} ${this.resources.payment.credit.interestFree}`,
            }))
        },
    },
    created() {
        this.$root.$refs.CreditCardForm = this;
    },
    data() {
        return {
            monthOptions: [
                { value: '01', label: '01' },
                { value: '02', label: '02' },
                { value: '03', label: '03' },
                { value: '04', label: '04' },
                { value: '05', label: '05' },
                { value: '06', label: '06' },
                { value: '07', label: '07' },
                { value: '08', label: '08' },
                { value: '09', label: '09' },
                { value: '10', label: '10' },
                { value: '11', label: '11' },
                { value: '12', label: '12' },
            ],
        }
    }
}
</script>
